<template>
  <div class="overlay">
    <h1>อุปกรณ์กำลังทำการคาลิเบรท...</h1>
    <p>
      อุปกรณ์ของคุณอยู่ระหว่างทำการคาลิเบรท หากคุณไม่ได้ทำการคาลิเบรทอุปกรณ์
      โปรดยกเลิกการคาลิเบรท
    </p>
    <b-button
      @click="onCancelCalibration"
      style="width: fit-content; align-self: center"
      type="is-danger"
      >ยกเลิกการคาลิเบรท</b-button
    >
  </div>
</template>

<script>
export default {
  name: "CalibrationOverlay",
  methods: {
    onCancelCalibration() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.overlay {
  position: absolute;
  /* color: white; */
  background-color: rgba(255, 255, 255, 0.95);
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 10rem 1rem;
  justify-items: center;
}

.overlay > h1 {
  font-size: 3rem;
}
</style>
